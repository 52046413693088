body{
  height: 100vh;
  height: -webkit-fill-available;
  overflow: hidden;
}

.container{
  max-height: 100vh;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  display: grid;
  padding: 12px;
  box-sizing: border-box;
  background: linear-gradient(266.56deg, #FFE0ED 20.62%, #FFD5D5 81.46%);
  grid-template-columns: 1fr 1fr;
  gap: 48px;
  overflow: hidden;
}



.areas-container{
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100vh;
  max-height: 100vh;
  justify-content: flex-start;
}

.areas-container textarea{
  height: 40%;
  background: rgba(0,0,0,0.05);
  resize: none;
  border-radius: 24px;
  border: none;
  padding: 12px;
  box-sizing: border-box;
  font-size: 14px;
  outline: none;
  transition: 0.1s ease-in-out;
}

.areas-container textarea:focus{
  background-color: white;
}

.chat-container{
  height: 100%;
  max-height: 94vh;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: scroll;
}

.message:last-child{
  margin-bottom: 120px;
}

.message{
  max-width: 75%;
  background-color: white;
  border-radius: 24px;
  display: flex;
  word-break: break-word;
  position: relative;
  padding: 10px 16px 10px 16px;
}

.message .model-id{
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -12px;
  margin-right: -12px;
  background-color: rgba(255,255,255,0.5);
  font-size: 12px;
  border-radius: 24px;
  padding: 6px 8px;
}

.message.user{
  align-self: flex-end;
  background-color: rgb(255, 199, 194);
}

.message.bot{
  align-self: flex-start;
}

.fixed-input{
  position: fixed;
  bottom: 24px;
  right: 12px;
  padding: 16px 32px;
  height: 60px;
  box-sizing: border-box;
  font-size: 18px;
  border: none;
  border-radius: 999px;
  outline: none;
  width: 47vw;
}


.settings-btn{
  display: none;
}

.fixed-btn{
  position: fixed;
  bottom: 24px;
  right: 12px;
  height: 60px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  background-color: white;
  transition: 0.1s ease-in-out;
  border-radius: 999px;
  font-size: 18px;
  font-weight: 600;
  width: 12vw;
}

.delete{
  height: 50px;
  border-radius: 24px;
  box-sizing: border-box;
  border: none;
  background-color: black;
  color: white;
  font-size: 18px;
  cursor: pointer;
}


.save{
  
}

@media screen and (max-width: 800px) {
    .desktop{
      display: none;
    }

    .container{
      grid-template-columns: 1fr;
    }

    .chat-container{
      height: -webkit-fill-available;
    }
    .fixed-input{
      width: 100vw;
      left: 0;
      margin: 0;
      border-radius: 0;
      bottom: 0;
    }

    body{
      height: 100vh;
      height: -webkit-fill-available;

    }

    .fixed-btn{
      bottom: 0;
      right: 0;
      width: 72px;
    }

    .settings-btn{
      position: fixed;
      top: 72px;
      right: 8px;
      padding: 8px;
      border-radius: 8px;
      border: none;
      background-color: black;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;

    }

    .mobile-overlay{
      padding: 8px;
      box-sizing: border-box;
      height: -webkit-fill-available;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 10;
      background: linear-gradient(266.56deg, #FFE0ED 20.62%, #FFD5D5 81.46%);
    }
}

.request-url-btns{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.request-url-btns button{
  padding: 8px 12px;
  background-color: #333;
  color: white;
  border-radius: 32px;
  border:  none;
  font-size: 16px;
}

.input-row{
  display: flex;
  gap: 8px;
  align-items: center;
}

.input-row input {
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  font-size: 16px;
}

.request-select{

  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  font-size: 16px;
  box-sizing: border-box;
}

.overlay{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  bottom: 0;
}

.overlay-content{
  min-width: 400px;
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  border-radius: 32px;
  gap: 4px;
  background-color: white;
}

.overlay-content button{
  height: 50px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: #000;
  color: white;
}